import Header from "../components/js/Header"
import Container from "../components/js/Container"
import { useMediaQuery } from "react-responsive";
import Footer from "../components/js/Footer";
import HeaderMobile from "../components/js/HeaderMobile";

let block = {
    padding:'12px',
    background:'rgb(245, 245, 245)',
    borderRadius:'12px',
    marginBottom:'12px',
}

const Contacts = () =>{
    let isMobile = useMediaQuery({
        query: "(max-width: 786px)"
      });
    return (
        <div>
            {isMobile ? <HeaderMobile /> : <Header />}
            <Container>
                    <div className="title">Контакты</div>
                    <div style={{
                        color:'#464445',
                        fontSize:'18px',
                    }}>
                        <div style={block}>                        
                            <div style={{marginBottom:'12px'}}>
                                Офис: город Ижевск, улица Репина, дом 30
                            </div>
                            <div style={{marginBottom:'12px'}}>Телефон: +7 909 054-01-74</div>
                        </div>
                        <div style={block}>
                            <div style={{fontSize:"22px", marginBottom:'12px'}}>Режим работы:</div>
                                <div style={{marginBottom:'4px', marginLeft:'12px'}}>Winestore г.Ижевск, ул Лихвинцева 46 – c 10 до 22</div>
                                <div style={{marginBottom:'4px', marginLeft:'12px'}}>Winestore г.Ижевск, ул.Молодежная 69 – c 9 до 21</div>
                                <div style={{marginBottom:'4px', marginLeft:'12px'}} >Winestore г.Ижевск, ул.Репина 30 – c 9 до 21</div>
                                <div style={{marginBottom:'4px', marginLeft:'12px'}} >Winestore г.Ижевск, ул.Азина 152 – c 9 до 21</div>
                                <div style={{marginBottom:'4px', marginLeft:'12px'}}>Winestore г.Ижевск, ул Горького 157 – c 10 до 22</div>
                                <div style={{marginBottom:'4px', marginLeft:'12px'}}>Winestore г.Ижевск, ул 7 Подлесная, 71 с 10.00 до 22.00</div>
                                <div style={{marginBottom:'4px', marginLeft:'12px'}}>Winestore г.Глазов, ул Сибирская 6 – c 9 до 20</div>
                                <div style={{marginLeft:'12px'}}>Winestore г.Сарапул, ул. Горького 12 – c 9 до 21</div>
                        </div>
                        <div style={block}>
                        <div style={{fontSize:"22px", marginBottom:'12px'}}>Арендодателям</div>
                            <div style={{marginBottom:'4px'}}>Городилов Евгений</div>
                            <div>телефон: +7 919 913-72-86, email: arenda@glvz.net</div>
                        </div>
                        <div style={block}>
                            <div style={{fontSize:"22px", marginBottom:'12px'}}>Работа у нас</div>
                            <div style={{marginBottom:'4px'}}>телефон: +7 341 273-80-60</div>
                            <div>телефон: +7 912 010-36-99, Екатерина Борисовна, email: RABOTA@glvz.net</div>
                        </div>
                        <div style={block}>
                            <div style={{fontSize:"22px"}} >Поставщикам</div>
                            <div>email: zakupki@glvz.net</div>
                        </div>
                </div>
            </Container>
            <Footer />
        </div>
    )
}

export default Contacts